import React from "react";

export const CakeSection = () => {
  return (
    <div className="py-20 bg-[#333333] text-white">
      <div className="flex justify-center items-center mb-10">
        <h1 className="font-semibold text-3xl">Happenings</h1>
      </div>
      <div className="py-8 lg:py-16 px-2 w-full max-w-5xl mx-auto flex flex-col md:flex-row md:items-center gap-10 lg:gap-x-20">
        <img
          src="/M1.jpg"
          alt="Refreshing espresso martini at Mercury, Kingston's premier dining and cocktail destination."
          width={570}
          height={400}
          className="object-cover lg:pb-0 h-[600px] pb-8"
        />
        <div className="flex flex-col justify-center items-center lg:w-1/2 lg:pt-0 pt-8 px-4">
          <h2 className="my-5 font-semibold text-2xl">Meet Espresso Martini</h2>
          <p className="w-full lg:w-3/4 text-center">
            The Espresso Martini is the perfect blend of sophistication and
            energy, combining the rich, bold flavors of espresso with the smooth
            elegance of vodka. It's the ultimate cocktail for those who
            appreciate the finer things in life.
          </p>
          {/* <a href="#content" className="py-3 px-8 bg-[#a76435] hover:bg-[#a76435]/80 my-7 font-medium">
              Reserve
            </a> */}
        </div>
      </div>
    </div>
  );
};
