import React from "react";
import { CategoryContent } from "./CategoryContent";
import {
  beerContent,
  breadContent,
  ciderContent,
  cocktail,
  dessertContent,
  fishContent,
  goatContent,
  meatContent,
  spiritContent,
  //   veggiesContent,
  winesContent,
  coffeContent,
  smoothies,
  mocktails,
  pubgrub,
} from "./MenuContent";

export const CategoryMenuSection = ({ title }) => {
  return (
    <div className="w-3/4 m-auto pb-[60px]">
      <h1 className="p-[30px] font-semibold">{title}</h1>
      {title === "Drinks" ? (
        <>
          <CategoryContent title="COCKTAILS" content={cocktail} />
          <CategoryContent
            title="BEER ON TAP (18 Oz Glass)"
            content={beerContent}
          />
          <CategoryContent title="BOTTLES" content={ciderContent} />
          <CategoryContent title="SHAKES" content={spiritContent} />
          <CategoryContent title="SMOOTHIES" content={smoothies} />
          <CategoryContent title="MOCKTAILS" content={mocktails} />
          <div>
            <h1 className="flex justify-center font-semibold text-xl py-5">
              WINE
            </h1>
            <div className="md:p-[30px] p-0 flex flex-wrap">
              {winesContent?.map((c, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/2 sm:w-[85%] md:pr-10 p-0 mb-[30px]"
                >
                  <div className="border-b pb-5">
                    <div className="font-semibold text-sm">
                      <label className="uppercase">{c?.name}</label>
                    </div>
                    <div className="text-xs pt-5 font-semibold">
                      <div className="flex justify-between">
                        <label>6 OZ</label>
                        <label>{c?.oz}</label>
                      </div>
                      <div className="flex justify-between">
                        <label>500 ML</label>
                        <label>{c?.glass}</label>
                      </div>
                      <div className="flex justify-between">
                        <label>750 ML</label>
                        <label>{c?.bottle}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <CategoryContent title="COFFEE & MORE" content={coffeContent} />
        </>
      ) : title === "Food" ? (
        <>
          <CategoryContent title="Greens Galore" content={breadContent} />
          <CategoryContent title="Social Plate" content={fishContent} />
          <CategoryContent title="Main Attraction" content={meatContent} />
          <CategoryContent title="Handheld Delights" content={goatContent} />
          <CategoryContent title="Sweet Endings" content={dessertContent} />
        </>
      ) : (
        <>
          <CategoryContent title="" content={pubgrub} />
        </>
      )}
    </div>
  );
};
