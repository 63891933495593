import React from "react";
import NaVBar from "../components/HomeNav";
import "../../src/styles.css";
import HeroCarousel from "./HeroCarousel";
import { Footer } from "../components/HomeFooter";
import { CakeSection } from "../components/landing-page/CakeSection";
import { AboutSection } from "../components/landing-page/AboutSection";
import { ReservationSection } from "../components/landing-page/ReservationSection";
import { ContectSection } from "../components/landing-page/ContectSection";

const Homepage = () => {
  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "Restaurant",
    telephone: "613 766 7225",
    email: "info@themercury.ca",
    address: {
      "@type": "PostalAddress",
      streetAddress: "125 Brock St",
      addressLocality: "Kingston",
      addressRegion: "Ontario",
      addressCountry: "Canada",
      postalCode: "K7L 1S1",
    },
    menu: "https://www.themercury.ca/menu",
    servesCuisine: "Global Cuisine",
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: "Everyday",
      opens: "12:00",
      closes: "23:00",
    },
  };
  return (
    <>
      <head>
        <title>Mercury Bar in Downtown Kingston </title>
        <meta
          name="description"
          content="Discover Mercury, a top restaurant in Kingston offering exquisite global cuisine and a vibrant ambience. Perfect for dining, meetings, and events. Enjoy exceptional service and a full bar. Indulge in our chef's specialties and experience world-class flavors. Book your table now for an unforgettable culinary journey!"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaMarkup) }}
        />
      </head>
      <div className="homepage">
        <div className="background-image"></div>
        <div className="content">
          <NaVBar />
          <HeroCarousel />
          <ContectSection />
          <div className="h-[630px]"></div>
          <AboutSection />
          <ReservationSection />
          <CakeSection />
          {/* <HardSection /> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Homepage;
