import React, { useEffect, useState } from "react";
import PopUp from "../CustomModal";

export const ReservationSection = () => {
  // State variables to store form values
  const [date, setDate] = useState("2024-05-15");
  const [time, setTime] = useState("06:26");
  const [option, setOption] = useState(""); // Default option

  useEffect(() => {
    // Check if the URL has a hash and navigate to the corresponding section
    const hash = window.location.hash;
    if (hash === "#reserve") {
      const reserveSection = document.getElementById("reserve");
      if (reserveSection) {
        reserveSection.scrollIntoView();
      }
    }
  }, []);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Use the form values as needed (e.g., submit them to a backend server)
    console.log("Date:", date);
    console.log("Time:", time);
    console.log("Option:", option);
  };

  return (
    <div
      id="reserve"
      className="bg-[url(/public/ReservationImage.jpg)] h-[500px] bg-black bg-cover bg-center flex justify-center items-center"
      about="Feast of multiple dishes showcasing Mercury's culinary excellence in Kingston, Downtown"
    >
      <div className="text-white flex justify-center items-center h-full flex-col">
        <div className="mb-10">
          <h1
            className="mt-5 mb-[10px] font-medium text-3xl"
            style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)" }}
          >
            Make a Reservation
          </h1>
        </div>
        <div className="p-4">
          {/* <PopUp/> */}
          <a
            href="https://www.opentable.ca/r/mercury-reservations-kingston?restref=1380022&lang=en-CA&ot_source=Restaurant%20website"
            className="inline-block py-4 px-14 bg-[#a76435] hover:bg-[#a76435]/90 my-3 font-medium shadow-lg text-center"
          >
            Find a Table
          </a>
        </div>
      </div>
    </div>
  );
};
